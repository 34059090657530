import './App.css';
import Main from './Components/Main';
import CssBaseline from '@mui/material/CssBaseline';


function App() {
  return (
    <>
      <CssBaseline />
      <Main />
    </>
  );
}

export default App;
