import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import * as yup from "yup";
import axios from "axios";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import swImg from "../image/Kundal_Logo.png";
import waImg from "../image/whatsapp.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REACT_APP_SITE_KEY } from "../constants/reCaptcha";
import { API } from "../constants/API";
import { Controller, useForm } from "react-hook-form";
const defaultValues = {
  gender: "",
  full_name: "",
};
const schema = yup.object().shape({
  gender: yup
    .string()
    .oneOf(["male", "female"])
    .required("You must select gender"),
  full_name: yup.string().required("You must enter a Full name"),
});

const Main = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [reCatchToken, setReCatchToken] = useState();

  const fontNormalStyle = {
    fontSize: "15px",
    fontWeight: "500",
    marginBottom: "5px",
  };

  const showAlertToast = (type, message) => {
    switch (type) {
      case "warn":
        toast.warn(message);
        break;
      case "success":
        toast.success(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "Alert":
        toast.alert(message);
        break;
      default:
    }
  };

  const handleReCaptchaVerify = useCallback((token) => {
    setReCatchToken(token);
  }, []);

  const onSubmit = async (formValue) => {
    const data = {
      full_name: formValue.full_name,
      gender: formValue.gender,
      token: reCatchToken,
    };

    const url = `${API}/api/contact`;
    try {
      const response = await axios.post(url, data);

      if (response.status === 200) {
        const link = response.data.data;
        window.open(link[0].link);
        reset();
        window.history.pushState(null, "", window.location.href);
      } else {
        showAlertToast("error", response.message);
      }
      reset();
    } catch (error) {
      showAlertToast("error", "Please try again later");
    }
  };

  useEffect(() => {
    console.log("errors: ", errors);
  }, [errors]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="flex justify-center items-center">
        <div className="h-auto w-full p-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="h-auto w-full flex justify-center items-center my-2 gap-2">
              <img src={swImg} width={"76px"} height={"76px"} alt="Maharaj" />
              <h1 className="text-[20px] text-center text-[#3E3E3E] font-bold">
                SMK WhatsApp Form
              </h1>
            </div>
            <hr className="h-[2px] border-[#D9D9D9]" />
            <Box
              className={`flex w-full flex-col items-center justify-center pt-5 gap-3 p-3`}
            >
              <Box className={`flex flex-col w-full p-[2px]`}>
                <Typography className="w-full mb-1-" sx={fontNormalStyle}>
                  Full Name (પૂરું નામ)
                </Typography>
                <Controller
                  control={control}
                  name="full_name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ borderRadius: "999px" }}
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                      error={!!errors.full_name}
                      helperText={errors?.full_name?.message}
                      id="kirtan_title_english"
                      size="small"
                      className="p-2 "
                      autoComplete="off"
                      placeholder=" Full Name"
                    />
                  )}
                />
              </Box>
              <Box className={`flex flex-col w-full p-[2px]`}>
                <Typography className=" w-full" sx={fontNormalStyle}>
                  Gender (જાતિ)
                </Typography>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <ToggleButtonGroup
                      className="text-black flex justify-evenly gap-5"
                      value={field.value}
                      error={!!errors.gender}
                      helperText={errors?.gender?.message}
                      exclusive
                      onChange={(event, newValue) => field.onChange(newValue)}
                      aria-label="Gender"
                    >
                      <ToggleButton
                        value="male"
                        sx={{
                          height: "35px",
                          width: "100%",
                          textTransform: "none",
                          borderRadius: "10px !important",
                          "&.Mui-selected": {
                            backgroundColor: "#4398fa !important",
                            color: "white !important",
                          },
                        }}
                      >
                        Male (પુરુષ)
                      </ToggleButton>
                      <ToggleButton
                        sx={{
                          height: "35px",
                          width: "100%",
                          textTransform: "none",
                          borderRadius: "10px !important",
                          borderLeft: "1px solid #e0e0e0 !important",
                          "&.Mui-selected": {
                            backgroundColor: "#fa66a4 !important",
                            color: "white !important",
                          },
                        }}
                        value="female"
                      >
                        Female (સ્ત્રી)
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
                {errors.gender && (
                  <p className="text-xs text-[#D32F2F] mt-1 mx-3">
                    {"You must select gender"}{" "}
                  </p>
                )}
              </Box>

              <Box className={"flex flex-col w-full p-[2px] relative"}>
                <Button
                  type="submit"
                  className={`flex flex-row w-full`}
                  sx={{ padding: "0px" }}
                  size="small"
                >
                  <Box
                    className="w-full flex justify-between items-center rounded-lg"
                    sx={{
                      padding: "0px",
                      backgroundColor: "#43C65A",
                      //  "#b9eac2",
                      cursor: "pointer",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    <Box
                      className="h-auto w-full flex justify-center items-center my-2 gap-2"
                      style={{ flex: 4 }}
                    >
                      <img
                        src={waImg}
                        className="rounded-full"
                        width={"34px"}
                        height={"34px"}
                        alt="Maharaj"
                      />
                      <p
                        className={`text-center pt-1 font-semibold text-base ${"text-white"}`}
                      >
                        Join WhatsApp Group
                      </p>
                    </Box>
                  </Box>
                </Button>
              </Box>
              <Box className={`flex flex-col w-full p-[2px]`}>
                <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_SITE_KEY}>
                  <GoogleReCaptcha
                    onVerify={handleReCaptchaVerify}
                    refreshReCaptcha={false}
                  />
                </GoogleReCaptchaProvider>
              </Box>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

export default Main;
